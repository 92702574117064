import { SLIDER_FILTERS_CODES } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Scandipwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        const { requireInfo, onlyAttributeMetadata } = options;
        if (onlyAttributeMetadata) {
            return this._getCustomAttributeMetadata();
        }
        if (requireInfo) {
            return [
                this._getProductsField(),
                this._getCustomAttributeMetadata(),
                this._getSubbrandMapping()
            ];
        }

        return this._getProductsField();
    }

    _getCustomFilters(filters = {}) {
        return Object.entries(filters)
            .reduce((acc, [key, attribute]) => {
                if (!attribute.length) {
                    return acc;
                }

                if (SLIDER_FILTERS_CODES.includes(key)) {
                    return {
                        ...acc,
                        ...this._getPriceFilter(key, attribute)
                    };
                }

                return {
                    ...acc,
                    [key]: { in: attribute }
                };
            }, {});
    }

    _getCustomAttributeMetadata() {
        const attrList = [
            {
                attribute_code: 'country',
                entity_type: 'catalog_product'
            },
            {
                attribute_code: 'brand',
                entity_type: 'catalog_product'
            },
            {
                attribute_code: 'subbrand',
                entity_type: 'catalog_product'
            },
            {
                attribute_code: 'format',
                entity_type: 'catalog_product'
            },
            {
                attribute_code: 'packaging_unit',
                entity_type: 'catalog_product'
            }
        ];

        return new Field('customAttributeMetadata')
            .addArgument('attributes', '[AttributeInput!]!', attrList)
            .addField(this._getCustomAttributeMetadataFields());
    }

    _getCustomAttributeMetadataFields() {
        return new Field('items')
            .addFieldList([
                'attribute_code',
                this._getCustomAttributeMetadataFieldOptions()
            ]);
    }

    _getCustomAttributeMetadataFieldOptions() {
        return new Field('attribute_options')
            .addFieldList([
                'value',
                'label'
            ]);
    }

    _getSubbrandMapping() {
        return new Field('brands')
            .addFieldList([
                'label',
                'value',
                this._getSubbrandsFields()
            ]);
    }

    _getSubbrandsFields() {
        return new Field('subbrands')
            .addFieldList(['label', 'value']);
    }
}

export default new ProductListQuery();

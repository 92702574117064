import { connect } from 'react-redux';

import {
    CategorySortContainer as SourceCategorySortContainer
} from 'SourceComponent/CategorySort/CategorySort.container';

/** @namespace Scandipwa/Component/CategorySort/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Scandipwa/Component/CategorySort/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/CategorySort/Container */
export class CategorySortContainer extends SourceCategorySortContainer {
    containerProps() {
        const {
            sortFields,
            isMobile
        } = this.props;

        return {
            ...super.containerProps(),
            isMobile,
            sortFields
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySortContainer);

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer
} from 'Component/ProductList/ProductList.container';
import { scrollToTop } from 'Util/Browser';

/** @namespace Insign/ScandipwaExtCatalog/Component/InsignProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Insign/ScandipwaExtCatalog/Component/InsignProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Insign/ScandipwaExtCatalog/Component/InsignProductList/Container */
export class InsignProductListContainer extends ProductListContainer {
    requestPage(currentPage = 1, isNext = false) {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device,
            canonicalObject
        } = this.props;

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort,
                filter,
                search,
                pageSize,
                currentPage
            }
        };

        const infoOptions = {
            args: {
                filter,
                search
            }
        };

        requestProductList(options);

        if (!isWidget) {
            const ignoredKeys = ['title', 'cms_block'];
            const canonicalArray = Object.keys(canonicalObject).filter((key) => !ignoredKeys.includes(key));

            if (canonicalArray.length) {
                infoOptions.isPlp = true;
                infoOptions.args.extCatalog = {
                    filter: {
                        type: canonicalArray[0].toUpperCase(),
                        value: canonicalObject[canonicalArray[0]]
                    }
                };
            }

            requestProductListInfo(infoOptions);

            if (!device.isMobile) {
                scrollToTop();
            }
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InsignProductListContainer));

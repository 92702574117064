/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import InsignProductList from '../InsignProductList/InsignProductList.container';
import { updateLoadStatus } from 'Store/ProductList/ProductList.action';
import { FilterInputType, SelectedFiltersType } from 'Type/Category.type';
import { LayoutType } from 'Type/Layout.type';
import { PagesType } from 'Type/ProductList.type';
import { 
    CategoryProductListContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps
 } from 'Component/CategoryProductList/CategoryProductList.container';

export const ProductListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductList/ProductList.dispatcher'
);

/** @namespace Component/CategoryProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
   ...sourceMapStateToProps(state)
});

/** @namespace Component/CategoryProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Insign/ScandipwaExtCatalog/Component/InsignCategoryProductList/Container */
export class InsignCategoryProductListContainer extends CategoryProductListContainer {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isMatchingListFilter: PropTypes.bool,
        isMatchingInfoFilter: PropTypes.bool,
        layout: LayoutType,
        filter: FilterInputType,
        requestProductList: PropTypes.func.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
        totalItems: PropTypes.number.isRequired,
        totalPages: PropTypes.number,
        search: PropTypes.string,
        sort: PropTypes.objectOf(PropTypes.string),
        selectedFilters: SelectedFiltersType,
        pages: PagesType.isRequired,
        isPageLoading: PropTypes.bool,
        isPlp: PropTypes.bool
    };

    static defaultProps = {
        isMatchingListFilter: false,
        isMatchingInfoFilter: false,
        isCurrentCategoryLoaded: false,
        filter: {},
        layout: 'grid',
        totalPages: 1,
        search: '',
        sort: undefined,
        selectedFilters: {},
        isPageLoading: false,
        isPlp: true
    };

    getIsLoading() {
        const {
            isLoading
        } = this.props;

        return isLoading;
    }

    getIsPreventRequest() {
        const { isMatchingListFilter, isMatchingInfoFilter } = this.props;

        return false; // if filter match - prevent request
    }

    requestProductList(org_options) {
        const { requestProductList, canonicalObject } = this.props;

        const ignoredKeys = ['title', 'cms_block'];
        const canonicalArray = Object.keys(canonicalObject).filter((key) => !ignoredKeys.includes(key));

        if (canonicalArray.length) {
            const options = {
                ...org_options,
                isPlp: true,
                args: {
                    ...org_options.args,
                    extCatalog: {
                        filter: {
                            type: canonicalArray[0].toUpperCase(),
                            value: canonicalObject[canonicalArray[0]]
                        }
                    }
                }
            };

            requestProductList(options);
        }
    }

    containerProps() {
        const { canonicalObject } = this.props;

        return {
            ...super.containerProps(),
            canonicalObject
        };
    }

    render() {
        return (
            <InsignProductList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsignCategoryProductListContainer);

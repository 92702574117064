import {
    DISPLAY_MODE_BOTH,
    DISPLAY_MODE_CMS_BLOCK,
    DISPLAY_MODE_PRODUCTS,
    GRID_LAYOUT,
    LAYOUT_KEY,
    LIST_LAYOUT,
    LOADING_TIME,
    SORT_DIRECTION_TYPE as SOURCE_SORT_DIRECTION_TYPE
} from 'SourceRoute/CategoryPage/CategoryPage.config';

export {
    LOADING_TIME,
    DISPLAY_MODE_PRODUCTS,
    DISPLAY_MODE_CMS_BLOCK,
    DISPLAY_MODE_BOTH,
    LAYOUT_KEY,
    GRID_LAYOUT,
    LIST_LAYOUT
};

// TODO: implement SORT_DIRECTION_TYPE
export const SORT_DIRECTION_TYPE = SOURCE_SORT_DIRECTION_TYPE;

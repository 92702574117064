import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_INFO,
    updateInfoLoadStatus
} from 'SourceStore/ProductListInfo/ProductListInfo.action';

export {
    UPDATE_PRODUCT_LIST_INFO,
    UPDATE_INFO_LOAD_STATUS,
    updateInfoLoadStatus
};

export const UPDATE_PRODUCT_ATTRIBUTE_INFO = 'UPDATE_PRODUCT_ATTRIBUTE_INFO';

/** @namespace Scandipwa/Store/ProductListInfo/Action/updateProductListInfo */
export const updateProductListInfo = (products, filter, attributeMetadata, brands) => ({
    type: UPDATE_PRODUCT_LIST_INFO,
    products,
    selectedFilter: filter,
    attributeMetadata,
    brands
});

/** @namespace Scandipwa/Store/ProductListInfo/Action/updateProductAttributeInfo */
export const updateProductAttributeInfo = (attributeMetadata) => ({
    type: UPDATE_PRODUCT_ATTRIBUTE_INFO,
    attributeMetadata
});

/* eslint-disable max-len */
import { lazy } from 'react';

import {
    Router as SourceRouter
} from 'SourceComponent/Router/Router.component';

import { AFTER_ITEMS_TYPE, AGE_VERIFICATION } from './Router.config';

export const AgeVerification = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/AgeVerification'));

/** @namespace Scandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    [AFTER_ITEMS_TYPE] = [
        ...this.AFTER_ITEMS_TYPE,
        {
            component: <AgeVerification />,
            position: 30,
            name: AGE_VERIFICATION
        }
    ];
}

export default RouterComponent;

import {
    getInitialState as sourceGetInitialState,
    ProductListReducer as SourceProductListReducer,
    reduceFilters
} from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import {
    UPDATE_PRODUCT_ATTRIBUTE_INFO,
    UPDATE_PRODUCT_LIST_INFO
} from 'Store/ProductListInfo/ProductListInfo.action';

export {
    reduceFilters
};

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    attributeMetadata: [],
    brandsMapping: []
});

/** @namespace Scandipwa/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (
    state = getInitialState(),
    action
) => {
    const { type } = action;

    switch (type) {
    case UPDATE_PRODUCT_LIST_INFO: {
        const {
            attributeMetadata,
            brands
        } = action;

        return {
            /* eslint-disable-next-line new-cap */
            ...SourceProductListReducer(state, action),
            attributeMetadata,
            brandsMapping: brands
        };
    }

    case UPDATE_PRODUCT_ATTRIBUTE_INFO:
        const {
            attributeMetadata
        } = action;

        return {
            ...state,
            attributeMetadata
        };

    default:
        /* eslint-disable-next-line new-cap */
        return SourceProductListReducer(state, action);
    }
};

export default ProductListReducer;

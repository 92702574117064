const _getArgumentsMap = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    extCatalog: {
        type: 'ExtCatalogInput',
        handler: (option) => option
    }
});

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getArgumentsMap
        }
    }
};

// export class NAMEContainerPlugin {
//     containerFunctions = (originalMember, instance) => ({
//         ...originalMember,
//         handleSomething: this.handleSomething.bind(instance)
//     });

//     handleSomething(arg) {
//         console.log(arg);
//     }
//  }

const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite: {
            // id,
            // sku,
            canonical_url
        }
    } = instance.props;

    // const isLoading = instance.getIsLoading();

    switch (instance.getType()) {
    case 'INSIGN_EXT_CATALOG':
        return {
            canonical_url
        };
    default:
        return callback.apply(instance, args);
    }
};

//  const { containerFunctions } = new NAMEContainerPlugin();

export default {
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps
        }
    }
};

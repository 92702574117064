/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_INPUT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES
} from 'SourceUtil/Validator/Config';

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    // Text
    [VALIDATION_INPUT_TYPE.alpha]: /^[a-zöäüëéèêàâôìïîûçæ]+$/i,
    [VALIDATION_INPUT_TYPE.alphaSpace]: /^[a-zöäüëéèêàâôìïîûçæ\s]+$/i,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{2}[-\s\\.]?[0-9]{2,4}$/im
};

export {
    VALIDATION_INPUT_TYPE,
    VALIDATION_INPUT_TYPE_TEXT,
    VALIDATION_INPUT_TYPE_NUMBER,
    VALIDATION_INPUT_TYPE_INPUT,
    VALIDATION_MESSAGES
};

import { Suspense } from 'react';

import CategorySort from 'Component/CategorySort';
import CigarFinderImages from 'Component/CigarFinderImages';
import FilterIcon from 'Component/FilterIcon';
import {
    CategoryFilterOverlay,
    CategoryPage as SourceCategoryPage
} from 'SourceRoute/CategoryPage/CategoryPage.component';

import './CategoryPage.override.style';

export {
    CategoryFilterOverlay
};

/** @namespace Scandipwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
    renderContent() {
        return (
            <>
                { /* { this.renderBrandsSidebar() } */ }
                { super.renderContent() }
            </>
        );
    }

    renderMiscellaneous() {
        return (
            <>
                { this.renderCigarFinderImages() }
                { super.renderMiscellaneous() }
            </>
        );
    }

    renderItemsCount(isVisibleOnMobile = false) {
        const {
            isCigarFinderAvailable
        } = this.props;

        if (isCigarFinderAvailable) {
            return null;
        }

        return super.renderItemsCount(isVisibleOnMobile);
    }

    renderCategoryDetails() {
        return null;
    }

    renderCigarFinderImages() {
        const {
            isCigarFinderVisible,
            isCigarFinderAvailable
        } = this.props;

        if (!isCigarFinderAvailable) {
            return null;
        }

        if (isCigarFinderVisible) {
            return (
                <CigarFinderImages />
            );
        }

        return null;
    }

    renderFilterOverlay() {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            isCigarFinderVisible,
            isCigarFinderAvailable,
            setCigarFinderVisible
        } = this.props;

        const { category: { is_anchor } } = this.props;

        if (!this.displayProducts()) {
            return null;
        }

        if (isCigarFinderAvailable === null) {
            return this.renderFilterPlaceholder();
        }

        return (
            <Suspense fallback={ this.renderFilterPlaceholder() }>
                <CategoryFilterOverlay
                  availableFilters={ filters }
                  customFiltersValues={ selectedFilters }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  isCategoryAnchor={ !!is_anchor }
                  isSearchPage={ isSearchPage }
                  renderPlaceholder={ this.renderPlaceholder }
                  isCigarFinderAvailable={ isCigarFinderAvailable }
                  isCigarFinderVisible={ isCigarFinderVisible }
                  setCigarFinderVisible={ setCigarFinderVisible }
                />
            </Suspense>
        );
    }

    renderFilterPlaceholder() {
        return (
            <div block="CategoryPage" elem="PlaceholderWrapper">
                <div block="CategoryPage" elem="PlaceholderContainer">
                    <div block="CategoryPage" elem="PlaceholderList">
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                        <div block="CategoryPage" elem="PlaceholderListItem" />
                    </div>
                </div>
            </div>
        );
    }

    renderCategorySort() {
        const {
            sortFields,
            selectedSort,
            onSortChange,
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            isMobile
        } = this.props;

        const { options = {} } = sortFields;
        const sortLabel = {
            position: __('Date')
        };
        // eslint-disable-next-line max-len
        const updatedSortFields = Object.values(options).map(({ value: id, label }) => ({ id, label: sortLabel[id] ?? label }));
        const { sortDirection, sortKey } = selectedSort;

        if (isMobile && !isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        return (
            <CategorySort
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
              isMatchingInfoFilter={ isMatchingInfoFilter }
              onSortChange={ onSortChange }
              sortFields={ updatedSortFields }
              sortKey={ sortKey }
              sortDirection={ sortDirection }
            />
        );
    }

    renderFilterButton() {
        const {
            isContentFiltered,
            totalPages,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter

        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderFilterButtonPlaceholder();
        }

        if ((!isContentFiltered && totalPages === 0) || !isCurrentCategoryLoaded) {
            return null;
        }

        return (
            <button
              block="CategoryPage"
              elem="Filter"
              onClick={ this.onFilterButtonClick }
            >
                <FilterIcon />
                <span>{ __('Filters') }</span>
                { this.renderFiltersCount() }
            </button>
        );
    }
}

export default CategoryPageComponent;

import ArrowIcon from 'Component/ArrowIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import { CategorySort as SourceCategorySortComponent } from 'SourceComponent/CategorySort/CategorySort.component';

import './CategorySort.override.style';

/**
 * Product Sort
 * @class ProductSort
 * @namespace Scandipwa/Component/CategorySort/Component */
export class CategorySortComponent extends SourceCategorySortComponent {
    renderSortField() {
        const {
            isMatchingInfoFilter,
            isCurrentCategoryLoaded,
            sortFields,
            isMobile
        } = this.props;

        if (!isMatchingInfoFilter || !isCurrentCategoryLoaded) {
            return this.renderPlaceholder();
        }

        if (isMobile) {
            return super.renderSortField();
        }

        return sortFields.map(this.renderSortOption.bind(this));
    }

    renderSortOption(option) {
        const {
            sortKey,
            sortDirection,
            onSortChange
        } = this.props;

        if (sortKey === option.id) {
            const nextSortDir = sortDirection === 'ASC' ? 'DESC' : 'ASC';
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  key={ option.id }
                  block="CategorySort"
                  elem="Option"
                  mods={ { isActive: true, [`dir_${sortDirection}`]: true } }
                  tabIndex="0"
                  role="button"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onSortChange(nextSortDir, option.id) }
                >
                    { option.label }
                    { option.id === sortKey
                        && <ArrowIcon direction={ sortDirection === 'ASC' ? TOP : BOTTOM } /> }
                </div>
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              key={ option.id }
              block="CategorySort"
              elem="Option"
              tabIndex="0"
              role="button"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => onSortChange('ASC', option.id) }
            >
                { option.label }
                { option.id === sortKey
                    && <ArrowIcon direction={ sortDirection === 'ASC' ? TOP : BOTTOM } /> }
            </div>
        );
    }
}

export default CategorySortComponent;

/* eslint-disable max-len, simple-import-sort/sort */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import HeartIcon from 'Component/HeartIcon';
import {
    ProductWishlistButton as SourceProductWishlistButton
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';
import './ProductWishlistButton.style';

/** @namespace Scandipwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <HeartIcon isActive={ isInWishlist } />
                <span block="ProductWishlistButton" elem="Text">{ __('Remember') }</span>
            </button>
        );
    }
}

export default ProductWishlistButtonComponent;

/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { PagesType } from 'Type/ProductList.type';

import './CigarFinderImages.style';

/** @namespace Scandipwa/Component/CigarFinderImages/Component */
export class CigarFinderImagesComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        totalItems: PropTypes.number.isRequired,
        totalPages: PropTypes.number,
        pages: PagesType.isRequired,
        isPageLoading: PropTypes.bool,
        currentPage: PropTypes.number.isRequired,
        scrollToProduct: PropTypes.func.isRequired
    };

    static defaultProps = {
        totalPages: 1,
        isPageLoading: false
    };

    renderProductsImages() {
        const {
            pages,
            currentPage
        } = this.props;

        if (Object.keys(pages).length === 0 || !pages[currentPage]) {
            return null;
        }

        return pages[currentPage].map(this.renderProductImage.bind(this));
    }

    renderProductImage(product, index) {
        const {
            scrollToProduct
        } = this.props;
        const {
            attributes: {
                length_mm = {}
            } = {},
            image: {
                url: img_url
            },
            name,
            id
        } = product;

        if (length_mm.attribute_value) {
            return (
                <div block="CigarFinderImages" elem="Image" key={ id }>
                    <div block="CigarFinderImages" elem="ImageWrapper">
                        <div block="CigarFinderImages" elem="ImageWrapperInner">
                            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
                            <img
                              src={ img_url }
                              alt={ name }
                              // eslint-disable-next-line no-magic-numbers
                              style={ { width: `${(100 * length_mm.attribute_value) / 250 }%` } }
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={ () => scrollToProduct(index) }
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div block="CigarFinderImages">
                { this.renderProductsImages() }
            </div>
        );
    }
}

export default CigarFinderImagesComponent;

import { PureComponent } from 'react';

import { RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import { DirectionType } from 'Type/Direction.type';

import './ArrowIcon.style';

/** @namespace Scandipwa/Component/ArrowIcon/Component */
export class ArrowIconComponent extends PureComponent {
    static propTypes = {
        direction: DirectionType
    };

    static defaultProps = {
        direction: RIGHT
    };

    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ArrowIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M8.7,13.7L14.4,8L8.7,2.3L7.3,3.7l3.6,3.4H1.6v1.8h9.2l-3.6,3.4L8.7,13.7z" />
            </svg>

        );
    }
}

export default ArrowIconComponent;

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PagesType } from 'Type/ProductList.type';
import { getQueryParam } from 'Util/Url';

import CigarFinderImages from './CigarFinderImages.component';

/** @namespace Scandipwa/Component/CigarFinderImages/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pages: state.ProductListReducer.pages,
    isLoading: state.ProductListReducer.isLoading,
    isPageLoading: state.ProductListReducer.isPageLoading,
    totalItems: state.ProductListReducer.totalItems,
    totalPages: state.ProductListReducer.totalPages
});

/** @namespace Scandipwa/Component/CigarFinderImages/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Scandipwa/Component/CigarFinderImages/Container */
export class CigarFinderImagesContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        totalItems: PropTypes.number.isRequired,
        totalPages: PropTypes.number,
        pages: PagesType.isRequired,
        isPageLoading: PropTypes.bool
    };

    static defaultProps = {
        totalPages: 1,
        isPageLoading: false
    };

    containerFunctions = {
        scrollToProduct: this.scrollToProduct.bind(this)
    };

    containerProps() {
        const {
            isLoading,
            isPageLoading,
            pages,
            totalItems,
            totalPages
        } = this.props;

        return {
            isLoading,
            isPageLoading,
            pages,
            totalItems,
            totalPages,
            currentPage: this._getPageFromUrl()
        };
    }

    scrollToProduct(index) {
        const element = document.querySelector('.CategoryProductList-Page').children[index];
        // eslint-disable-next-line no-magic-numbers
        const headerOffset = document.querySelector('header.Header').offsetHeight + 20;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    _getPageFromUrl(url) {
        // eslint-disable-next-line react/prop-types
        const { location: currentLocation } = this.props;
        const location = url || currentLocation;

        return +(getQueryParam('page', location) || 1);
    }

    render() {
        return (
            <CigarFinderImages
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CigarFinderImagesContainer));

const _getUrlResolverFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'canonical_url'
];

export default {
    'Query/UrlRewrites/Query': {
        'member-function': {
            _getUrlResolverFields
        }
    }
};

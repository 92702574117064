import {
    DETAILS_STEP,
    PAYMENT_TOTALS,
    SHIPPING_STEP
} from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId, setCartId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { RESTORE_CART_STEP } from '../config/Checkout.config';
import CwPaymentQuery from '../query/CwPayment.query';

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/paymentSuccessHandler */
export const paymentSuccessHandler = (args, originalConstructor, instance) => {
    const order_data = BrowserDatabase.getItem('cw_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'cw_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        isEmailAvailable,
        email
    } = order_data;

    // do similar things like in setDetailsStep function
    instance.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress: {},
        selectedShippingMethod: '',
        isGuestEmailSaved: true,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,
        checkoutStep: DETAILS_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id,
        isEmailAvailable
    };
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/restoreCart */
export const restoreCart = async (props) => {
    const guest_cart_id = !isSignedIn() ? getCartId() : props.totals.id;
    const { cwRestoreCart: { cart_id } } = await fetchMutation(CwPaymentQuery.getRestoreCartMutation(guest_cart_id));
    setCartId(cart_id);
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/defaultFailureHandler */
export const defaultFailureHandler = async (args, originalConstructor, instance) => {
    const [props] = args;
    const { showErrorNotification } = props;

    const order_data = BrowserDatabase.getItem('cw_payment_order_data');
    // remove order info from local storage
    BrowserDatabase.setItem({}, 'cw_payment_order_data', ONE_MONTH_IN_SECONDS);
    const {
        order_id,
        billingAddress,
        shippingAddress,
        email
    } = order_data;

    showErrorNotification(__('Payment process was canceled!'));
    instance.state = {
        isLoading: false,
        isDeliveryOptionsLoading: false,
        requestsSent: 0,
        paymentMethods: [],
        shippingMethods: [],
        shippingAddress,
        selectedShippingMethod: '',
        isGuestEmailSaved: false,
        isCreateUser: false,
        estimateAddress: {},
        isPickInStoreMethodSelected: false,
        checkoutStep: RESTORE_CART_STEP,
        paymentTotals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
        email,
        billingAddress,
        orderID: order_id
    };
    await restoreCart(props);
    await props.resetCart();

    BrowserDatabase.deleteItem(PAYMENT_TOTALS);

    // eslint-disable-next-line react/no-did-update-set-state
    instance.setState({
        checkoutStep: SHIPPING_STEP
    });
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/paymentFailureHandler */
export const paymentFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/processFailureHandler */
export const processFailureHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

/** @namespace Insign/ScandipwaCustomwebPayment/Helper/Checkout/Helper/processRestoreCartHandler */
export const processRestoreCartHandler = async (args, originalConstructor, instance) => {
    defaultFailureHandler(args, originalConstructor, instance);
};

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    ExpandableContent as SourceExpandableContent
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Scandipwa/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContent {
    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    closeExpanded = this.closeExpanded.bind(this);

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    closeExpanded() {
        this.setState({
            isContentExpanded: false
        });
    }

    render() {
        const { mix, mods, isDropdown } = this.props;

        if (isDropdown) {
            return (
                <ClickOutside onClick={ this.closeExpanded }>
                <article
                  block="ExpandableContent"
                  mix={ mix }
                  mods={ mods }
                  ref={ this.expandableContentRef }
                >
                    { this.renderButton() }
                    { this.renderContent() }
                </article>
                </ClickOutside>
            );
        }

        return (
            <article
              block="ExpandableContent"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace Scandipwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    __construct(props) {
        super.__construct(props);
        this.isShouldListenForBroadcast = false;
    }
}

export default CmsBlockContainer;

/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 import PropTypes from 'prop-types';
 import { PureComponent } from 'react';
 
 import './DeliveryIcon.style';
 
 /** @namespace Scandipwa/Component/Deliveryicon/DeliveryIcon/Component */
 export class DeliveryIconComponent extends PureComponent {
     static propTypes = {
         isActive: PropTypes.bool
     };
 
     static defaultProps = {
         isActive: false
     };
 
     render() {
         const { isActive } = this.props;
 
         return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 35 26.2">
            <path d="M34.6,19.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3v0.9c0,0.1-0.1,0.2-0.2,0.3C34.7,21,34.7,21,34.6,21
                h-3.2c0.1,0.3,0.1,0.6,0.1,0.9c0,1.2-0.4,2.2-1.3,3.1s-1.9,1.3-3.1,1.3S24.9,25.9,24,25s-1.3-1.9-1.3-3.1c0-0.3,0-0.5,0.1-0.9H12.1
                c0.1,0.3,0.1,0.6,0.1,0.9c0,1.2-0.4,2.2-1.3,3.1s-1.9,1.3-3.1,1.3S5.6,25.9,4.8,25s-1.3-1.9-1.3-3.1c0-0.3,0-0.5,0.1-0.9H2.7
                c-0.8,0-1.4-0.2-1.9-0.7C0.2,19.8,0,19.2,0,18.4V2.6c0-0.7,0.2-1.3,0.8-1.8C1.3,0.3,1.9,0,2.7,0h17.4c0.7,0,1.3,0.3,1.9,0.8
                c0.5,0.5,0.8,1.1,0.8,1.8v2.7h2.4c1,0,1.8,0.4,2.5,1l4.6,4.6c0.7,0.7,1,1.5,1,2.5v5.9H34.6z M25.2,7h-2.4v5.2h8.3L31,12.1l-4.6-4.6
                C26,7.2,25.6,7,25.2,7z M7.9,24.5c0.7,0,1.3-0.2,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9c0-0.7-0.3-1.3-0.8-1.9c-0.5-0.5-1.1-0.8-1.9-0.8
                c-0.8,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.1-0.8,1.9c0,0.8,0.2,1.4,0.8,1.9C6.5,24.3,7.1,24.5,7.9,24.5z M11.4,19.2H21V2.6
                c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.2-0.4-0.3-0.7-0.3H2.7C2.4,1.8,2.2,1.9,2,2C1.8,2.2,1.8,2.4,1.8,2.6v15.9c0,0.2,0.1,0.4,0.3,0.6
                c0.2,0.2,0.4,0.2,0.7,0.2h1.7c0.4-0.5,0.9-0.9,1.5-1.3c0.6-0.3,1.3-0.5,2-0.5c0.7,0,1.3,0.2,1.9,0.5C10.4,18.3,10.9,18.7,11.4,19.2z
                M27.1,24.5c0.7,0,1.3-0.2,1.9-0.8c0.5-0.5,0.8-1.1,0.8-1.9c0-0.7-0.3-1.3-0.8-1.9c-0.5-0.5-1.1-0.8-1.9-0.8c-0.8,0-1.4,0.3-1.9,0.8
                c-0.5,0.5-0.8,1.1-0.8,1.9c0,0.8,0.2,1.4,0.8,1.9C25.8,24.3,26.4,24.5,27.1,24.5z M27.1,17.5c0.7,0,1.3,0.2,1.9,0.5
                c0.6,0.3,1.1,0.7,1.6,1.3h0.9V14h-8.8v5.2h0.9c0.4-0.5,0.9-0.9,1.5-1.3C25.8,17.7,26.4,17.5,27.1,17.5z"/>
            </svg>
         );
     }
 }
 
 export default DeliveryIconComponent;
import {
    Popup as SourcePopup
} from 'SourceComponent/Popup/Popup.component';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    hidePopupAndGoBack() {
        this.hidePopUp();
    }
}

export default PopupComponent;

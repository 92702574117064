import TabaccoIconComponent from 'Component/TabaccoIcon/TabaccoIcon.component';
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

import './ProductAttributeValue.override.style';

export const specialAttributValues = ['aroma', 'flavor_strength'];
export const attributesWithUnits = ['length_mm', 'diameter_mm'];

/** @namespace Scandipwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    renderSpecialAttributeValues() {
        const {
            attribute
        } = this.props;

        return (

            <div block="ProductAttributeValue" elem="IconWrapper">
                <div block="ProductAttributeValue" elem="IconGray">
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                </div>
                <div
                  block="ProductAttributeValue"
                  elem="IconBrown"
                  style={ {
                      width: `${attribute.attribute_value }%`
                  } }
                >
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                    <TabaccoIconComponent />
                </div>

            </div>

        );
    }

    attributeRenderMap = {
        ring_gauge: this.renderRingGauge.bind(this)
    };

    renderRingGauge(attribute_code, attribute_value) {
        return this.renderStringValue(parseFloat(attribute_value).toFixed(0));
    }

    renderAttributesWithUnits(attribute_code, attribute_value) {
        if (attribute_code === 'length_mm') {
            // eslint-disable-next-line max-len, no-magic-numbers
            return this.renderStringValue(`${ parseFloat(attribute_value).toFixed(1) } mm / ${ Math.round((attribute_value / 25.4) * 100) / 100 }"`);
        }

        if (attribute_code === 'diameter_mm') {
            return this.renderStringValue(`${attribute_value } mm`);
        }

        return null;
    }

    render() {
        const {
            attribute: { attribute_code, attribute_value }
        } = this.props;

        if (specialAttributValues.includes(attribute_code)) {
            return this.renderSpecialAttributeValues();
        }

        if (attributesWithUnits.includes(attribute_code)) {
            return this.renderAttributesWithUnits(attribute_code, attribute_value);
        }

        if (this.attributeRenderMap[attribute_code]) {
            return this.attributeRenderMap[attribute_code](attribute_code, attribute_value);
        }

        return super.render();
    }
}

export default ProductAttributeValueComponent;

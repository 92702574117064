/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 import PropTypes from 'prop-types';
 import { PureComponent } from 'react';
 
 import './TabaccoIcon.style';
 
 /** @namespace Scandipwa/Component/TabaccoIcon/TabaccoIcon/Component */
 export class TabaccoIconComponent extends PureComponent {
     static propTypes = {
         isActive: PropTypes.bool
     };
 
     static defaultProps = {
         isActive: false
     };
 
     render() {
         const { isActive } = this.props;
 
         return (
            <svg width="20" height="20" viewBox="0 0 16 20">
                <g opacity="0.9">
                    <path d="M0 20C0 20 3.9934 16.9122 4.29449 11.3027C4.59558 5.6933 4.83856 3.68544 7.88115 1.67233C10.9237 -0.340777 16 0.0261955 16 0.0261955C16 0.0261955 10.4536 2.74703 9.81446 8.06814C9.17531 13.3892 9.40244 14.595 6.66094 16.9489C3.91945 19.3027 2.46682 19.6016 0 20.0052V20Z" />
                    <path d="M13.1264 4.28857C13.1264 4.28857 12.9785 6.92029 13.0841 9.62016C13.1369 10.9046 13.3165 12.1313 13.4644 13.2427C13.8923 16.4458 11.9379 18.3856 9.06431 18.4904C6.19075 18.5953 6.35978 18.4904 6.35978 18.4904C6.35978 18.4904 9.70874 17.0802 10.5222 13.6569C11.3357 10.2335 10.686 8.31479 13.1264 4.28857Z"/>
                </g>
            </svg>

         );
     }
 }
 
 export default TabaccoIconComponent;
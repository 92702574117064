/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    GroupedProductsItemContainer as SourceGroupedProductsItemContainer
} from 'SourceComponent/GroupedProductsItem/GroupedProductsItem.container';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/GroupedProductsItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/GroupedProductsItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartId: state.CartReducer.id,
    attributeMetadata: state.ProductListInfoReducer.attributeMetadata
});

/** @namespace Scandipwa/Component/GroupedProductsItem/Container */
export class GroupedProductsItemContainer extends SourceGroupedProductsItemContainer {
    static propTypes = {
        ...SourceGroupedProductsItemContainer.propTypes,
        showImages: PropTypes.bool,
        addProductToCart: PropTypes.func.isRequired,
        cartId: PropTypes.string
    };

    __construct(props) {
        const {
            product: {
                sku = 'fallback'
            } = {}
        } = props;

        super.__construct(props);

        this.popupId = `img-lb-${sku}`;
    }

    containerProps() {
        const {
            showImages,
            showPopup,
            hideActiveOverlay,
            attributeMetadata
        } = this.props;
        const { quantity } = this.state;

        return {
            ...super.containerProps(),
            showImages,
            quantity,
            showPopup,
            hideActiveOverlay,
            popupId: this.popupId,
            attributeMetadata
        };
    }

    state = {
        quantity: 1
    };

    onQuantityChange(value) {
        this.setState({
            quantity: value
        });
    }

    containerFunctions = {
        ...super.containerFunctions,
        addToCart: this.addToCart.bind(this),
        onQuantityChange: this.onQuantityChange.bind(this)
    };

    async addToCart() {
        // this.updateSelectedValues();
        const { showError, product } = this.props;

        // if (this.hasError()) {
        //     return;
        // }

        const { addProductToCart, cartId } = this.props;
        // const products = this.getMagentoProduct();

        const prod = {
            sku: product.sku,
            quantity: 1
        };

        await addProductToCart({ products: [prod], cartId })
            .catch(
                /** @namespace Scandipwa/Component/GroupedProductsItem/Container/GroupedProductsItemContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupedProductsItemContainer);

import { Field } from 'Util/Query';

/** @namespace Insign/ScandipwaCustomwebPayment/Query/CwPayment/Query */
export class CwPaymentQuery {
    getAuthorizeMutation(orderIncrementId) {
        return new Field('cwAuthorize')
            .addArgument('orderIncrementId', 'String', orderIncrementId)
            .addFieldList([
                'redirectionUrl',
                'formActionUrl',
                this._getHiddenFormField()
            ]);
    }

    _getHiddenFormField() {
        return new Field('hiddenFormFields')
            .addFieldList([
                'key',
                'value'
            ]);
    }

    getRestoreCartMutation(cartId) {
        return new Field('cwRestoreCart')
            .addArgument('input', 'CwResetCartInput!', { cart_id: cartId })
            .addField('cart_id');
    }
}

export default new CwPaymentQuery();

import {
    ProductListInfoDispatcher as SourceProductListInfoDispatcher
} from 'SourceStore/ProductListInfo/ProductListInfo.dispatcher';
import {
    updateProductAttributeInfo,
    updateProductListInfo
} from 'Store/ProductListInfo/ProductListInfo.action';

/** @namespace Scandipwa/Store/ProductListInfo/Dispatcher */
export class ProductListInfoDispatcher extends SourceProductListInfoDispatcher {
    onSuccess({ products, brands, customAttributeMetadata: { items = [] } = {} }, dispatch, options) {
        const {
            args: {
                filter
            },
            onlyAttributeMetadata
        } = options;

        if (onlyAttributeMetadata) {
            dispatch(updateProductAttributeInfo(items));
        } else {
            dispatch(updateProductListInfo(products, filter, items, brands));
        }
    }
}

export default new ProductListInfoDispatcher();

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export {
    formatCurrency,
    calculateFinalPrice,
    calculateTierDiscountOverSpecialPrice,
    roundPrice
} from 'SourceUtil/Price/Price';

/** @namespace Scandipwa/Util/Price/formatPrice */
export const formatPrice = (price, currentCurrency) => {
    const currency = currentCurrency || 'CHF';

    return new Intl.NumberFormat('de-CH', { style: 'currency', currency }).format(price);
};

/** @namespace Scandipwa/Util/Price/getLowestPriceTiersPrice */
export const getLowestPriceTiersPrice = (price_tiers, currency) => {
    const lowestValue = price_tiers
        .reduce((acc, { final_price: { value } }) => (acc < value ? acc : value), price_tiers[0].final_price.value);

    return formatPrice(lowestValue, currency);
};

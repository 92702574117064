/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { getBaseImage } from 'Util/Product/Extract';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    attributeMetadata: state.ProductListInfoReducer.attributeMetadata
});

/** @namespace Scandipwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    toggleOpended() {
        this.setState((currentState) => ({ isOpened: !currentState.isOpened }));
    }

    containerFunctions = {
        ...this.containerFunctions,
        toggleOpended: this.toggleOpended.bind(this)
    };

    state = {
        ...this.state,
        isOpened: false
    };

    containerProps() {
        const {
            isOpened
        } = this.state;
        const {
            product,
            attributeMetadata
        } = this.props;

        return {
            ...super.containerProps(),
            list_thumbnail: getBaseImage(this.getActiveProduct()) || getBaseImage(product),
            isOpened,
            attributeMetadata
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);

import { connect } from 'react-redux';

import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import CategoryReducer from 'Store/Category/Category.reducer';
import { withReducers } from 'Util/DynamicReducer';
import {
    getQueryParam
} from 'Util/Url';

import {
    GRID_LAYOUT,
    LIST_LAYOUT,
    SORT_DIRECTION_TYPE
} from './CategoryPage.config';

/** @namespace Scandipwa/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    attributeMetadata: state.ProductListInfoReducer.attributeMetadata
});

/** @namespace Scandipwa/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Scandipwa/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    state = {
        ...this.state,
        selectedLayoutType: window.location.pathname.indexOf('cigarfinder') > -1 ? LIST_LAYOUT : GRID_LAYOUT,
        isCigarFinderVisible: window.location.pathname.indexOf('cigarfinder') > -1
            || window.location.search.indexOf('customFilters') > -1
    };

    containerFunctions = {
        ...this.containerFunctions,
        setCigarFinderVisible: this.setCigarFinderVisible.bind(this)
    };

    config = {
        sortKey: 'position',
        sortDirection: SORT_DIRECTION_TYPE.desc
    };

    setCigarFinderVisible(visible) {
        this.setState({ isCigarFinderVisible: visible });
    }

    containerProps() {
        const {
            attributeMetadata
        } = this.props;
        const {
            isCigarFinderVisible
        } = this.state;

        return {
            ...super.containerProps(),
            attributeMetadata,
            isCigarFinderVisible,
            isCigarFinderAvailable: this.isCigarFinderAvailable(),
            isCigarFinderPage: this.isCigarFinderPage()
        };
    }

    isCigarFinderPage() {
        return window.location.pathname.indexOf('cigarfinder') > -1;
    }

    isCigarFinderAvailable() {
        const {
            category
        } = this.props;

        if (category.id === undefined) {
            return null;
        }

        // temporary hardcoded for category id 3
        // eslint-disable-next-line no-magic-numbers
        return category.id === 3;
    }

    getSelectedSortFromUrl() {
        const {
            location,
            category: {
                default_sort_by
            }
        } = this.props;

        const {
            sortKey: globalDefaultSortKey,
            sortDirection: defaultSortDirection
        } = this.config;

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         * */
        const sortDirection = getQueryParam('sortDirection', location) || defaultSortDirection;

        /**
         * Default SORT KEY is taken from (sequentially):
         * - URL param "sortKey"
         * - Category default sort key (Magento 2 configuration)
         * - CategoryPage class property "config"
         * */
        const defaultSortKey = default_sort_by || globalDefaultSortKey;
        const sortKey = getQueryParam('sortKey', location) || defaultSortKey;

        return {
            sortDirection,
            sortKey
        };
    }
}

export default withReducers({
    CategoryReducer
})(connect(mapStateToProps, mapDispatchToProps)(CategoryPageContainer));

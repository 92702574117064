/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CartIcon.style';

/** @namespace Scandipwa/Component/CartIcon/Component */
export class CartIconComponent extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg               
              block="CartIcon"
              mods={ { isActive } }
              width="25"
              height="30"
              viewBox="0 0 25 35"
              xmlns="http://www.w3.org/2000/svg">
            <path d="M21 8.25H26.625V26.0625C26.625 27.3516 26.1562 28.4648 25.2188 29.4023C24.2812 30.3398 23.2266 30.75 21.9375 30.75H5.0625C3.77344 30.75 2.66016 30.3398 1.72266 29.4023C0.785156 28.4648 0.375 27.3516 0.375 26.0625V8.25H6C6 6.90234 6.29297 5.67188 6.99609 4.5C7.64062 3.32812 8.51953 2.44922 9.69141 1.74609C10.8633 1.10156 12.0938 0.75 13.5 0.75C14.8477 0.75 16.0781 1.10156 17.25 1.74609C18.4219 2.44922 19.3008 3.32812 20.0039 4.5C20.6484 5.67188 21 6.90234 21 8.25ZM13.5 2.625C11.918 2.625 10.5703 3.21094 9.51562 4.26562C8.40234 5.37891 7.875 6.72656 7.875 8.25H19.125C19.125 6.72656 18.5391 5.37891 17.4844 4.26562C16.3711 3.21094 15.0234 2.625 13.5 2.625ZM24.75 26.0625V10.125H21V12.9375C21 13.2305 20.8828 13.4648 20.707 13.6406C20.5312 13.8164 20.2969 13.875 20.0625 13.875C19.7695 13.875 19.5352 13.8164 19.3594 13.6406C19.1836 13.4648 19.125 13.2305 19.125 12.9375V10.125H7.875V12.9375C7.875 13.2305 7.75781 13.4648 7.58203 13.6406C7.40625 13.8164 7.17188 13.875 6.9375 13.875C6.64453 13.875 6.41016 13.8164 6.23438 13.6406C6.05859 13.4648 6 13.2305 6 12.9375V10.125H2.25V26.0625C2.25 26.8828 2.48438 27.5273 3.07031 28.0547C3.59766 28.6406 4.24219 28.875 5.0625 28.875H21.9375C22.6992 28.875 23.3438 28.6406 23.9297 28.0547C24.457 27.5273 24.75 26.8828 24.75 26.0625Z"/>
            </svg>
            
        );
    }
}

export default CartIconComponent;
